<template>
    <v-combobox 
    v-model="selectedItems" 
    :items="this.multiselectItems" 
    item-text="value" 
    item-disabled="disabled"
    multiple outlined dense chips
    background-color="#F2F2F2" 
    height="60px" 
    @change="selectData">
    </v-combobox>
</template>

<script>
export default {
    props:['multiselectOptionsData','value','setPreselect'],
    data(){
        return{
            multiselectItems:[],
            selectedItems:[]
        }
    },
    computed:{
        selectedData(){
            let selectedData = this.selectedItems.map(option => {
                return{
                    'key': option.key,
                    'value': option.value
                }
            });
            return selectedData;
        }
    },
    methods:{
        selectData(){
            this.$emit("input", this.selectedData)
        }
    },
    mounted(){
        this.multiselectItems = this.$props.multiselectOptionsData;
        if(this.$props.setPreselect && !this.$props.value){
            this.selectedItems = this.multiselectItems.filter(option => option.preselected);
        } else if(this.$props.value){
            let optionsSelected = this.$props.value.map(option => {
              return option.value
            });
            this.selectedItems = this.multiselectItems.filter(option => optionsSelected.includes(option.value));
        }
        this.selectData();
    }
}
</script>