<template>
	<div style="display: flex; flex-direction: row;flex-wrap: wrap;">
		<div style="width:120px">
			<v-autocomplete
				placeholder="Code"
				outlined
				v-model="currencyCode"
				:items="this.currencyCodeList.length>0 ? this.currencyCodeList : this.listOfCurrencyCodes"
				filled
				:disabled= "isDisabled ||isFieldDisable"
			>
			</v-autocomplete>
		</div>
		<v-text-field
			placeholder="Enter The Amount"
			outlined
            style="margin-left: 5px;"
			background-color="#F2F2F2"
			v-model= currencyinput
			@change ="addCurrencyAmount(),formatCurrency(currencyinput)"
			:clearable= false
			:disabled= isFieldDisable
		>
		</v-text-field>
	</div>
</template>
<script>
const currencyCodes = require('currency-codes');
import { currencyFormat } from "../mixins/currencyFormat.js";
export default {
	props: ["value","fileDefinition","currencyData","fieldData","isdisabled"],
	data() {
		return {
			fieldItem : "",
			currencyinput: this.value,
			currencyCodeList:[],
			isDisabled:false,
			currencyCode:"",
			currencyCodeFormat:"",
			listOfCurrencyCodes:[],
			currencyinputData:{},
			updateFieldData:"",
			fieldKey:"",
			isFieldDisable:false
		}
	},
	mixins: [currencyFormat],
	watch:{
		value(newValue) {
			this.currencyinput = newValue;
			if (this.currencyinput) {
				this.formatCurrency(this.currencyinput)
				this.addCurrencyAmount()
			}
		},
		currencyCode(){
			if(this.currencyinput){
				this.formatCurrency(this.currencyinput)
				this.addCurrencyAmount()
			}
		}
	},
	methods: {
		formatCurrency(value) {
			value = value.toString().replace(/[^0-9.]/g, '');
			if(this.currencyCodeFormat && this.currencyCodeFormat.hasOwnProperty(this.currencyCode)) {
				this.currencyinput = this.formatCurrencyValue(this.currencyCode,this.currencyCodeFormat[this.currencyCode].digitsAfterdecimalPoint,value,this.currencyCodeFormat[this.currencyCode].system, "narrowSymbol");
			}
			else {
				this.currencyinput= this.formatCurrencyValue(this.currencyCode,2,value,"English", "narrowSymbol");
			}
		},
		addCurrencyAmount() {
			var amount= this.currencyinput.replace(/[^0-9.]/g, '')
			this.currencyinputData["currencyAmount"] = (Math.round(Number(amount) * 100) / 100).toFixed(2);
			this.$emit("addCurrencyDetails",this.currencyCode,this.fieldKey)
			this.$emit("input", Number(this.currencyinputData.currencyAmount));
		}
	},
	mounted() {
		this.isFieldDisable= this.$props.isdisabled;
		this.fieldItem = this.$props.fileDefinition;
		this.listOfCurrencyCodes = currencyCodes.codes();
		if(this.fieldItem) {
			this.fieldKey = this.fieldItem.key;
			if(this.$props.currencyData && this.$props.currencyData[[this.fieldItem.key]]){
				this.currencyCode = this.$props.currencyData[this.fieldItem.key]["currencyCode"];
			}else if(this.fieldItem.hasOwnProperty("currency_configuration")){
				this.currencyCodeList= this.fieldItem.currency_configuration.acceptedCurrencyList;
				this.currencyCodeFormat = this.fieldItem.currency_configuration.format;
				if(this.currencyCodeList.length === 1){
					this.isDisabled = true
					this.currencyCode = this.currencyCodeList[0];
				}
				else {
					this.currencyCode = this.fieldItem.currency_configuration.defaultCurrency;
					this.currencyinputData["currencyCode"] = this.currencyCode;
				}
			}
		}
		else {
			this.fieldKey = this.fieldData.key;
			this.updateFieldData = this.$props.fieldData;
			if(this.updateFieldData.currency_configuration){
				this.currencyCodeList= this.updateFieldData.currency_configuration.acceptedCurrencyList;
			}
			if(this.$props.currencyData && this.$props.currencyData[[this.$props.fieldData.key]]){
				this.currencyCode = this.$props.currencyData[this.$props.fieldData.key]["currencyCode"];
				if(this.updateFieldData.currency_configuration && this.updateFieldData.currency_configuration.format){
					this.currencyCodeFormat = this.updateFieldData.currency_configuration.format;
					this.formatCurrency(String(this.currencyinput))
					this.currencyCodeList= this.updateFieldData.currency_configuration.acceptedCurrencyList;
				}
				else{
					this.formatCurrency(String(this.currencyinput))
				}
			}
		}
	}
}
</script>