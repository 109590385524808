<template>
    <div>
        <v-card outlined class="elevation-0" id="text" v-if="showData">
            <v-row no-gutters v-for="(objValue, objKey) in value[keyName]">
                <v-col style="text-align: left;">
                    <v-sheet class="px-2 py-4">
                        {{ displayNames[objKey]['en'] }}
                    </v-sheet>
                </v-col>
                <v-col style="text-align: right;">
                    <v-sheet class="px-2 py-4">
                        {{ objValue }}
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>
<script>
export default {
    props: [
        "value", "keyName"
    ],
    data() {
        return {
           showData: false,
           displayNames: {}
        };
    },
    methods: {

    },
    mounted() {
        this.displayNames = this.value[this.keyName]['display_name'];
        delete this.value[this.keyName]['display_name'];
        if(this.displayNames){
            this.showData = true;
        }
    },
};
</script>
<style>
#text{
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    background-color: #f3f3f9;
}
</style>