<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title
          style="
            background-color: #ffffff;
            color: #2a409a;
            font-size: 20px;
            font-weight: 500;
          "
          >{{$t("Confirmation")}}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <br />
          <div
            style="
              font-size: 16px;
              font-weight: 500;
              font-style: normal;
              color: #000000;
            "
          >
           {{$t("Do you want to perform this operation ?")}}
          </div>
        </v-card-text>
        <v-divider />

        <v-card-actions style="display: flex; justify-content: right">
          <div style="margin-right: 30px">
            <v-btn
              outlined
              style="
                background-color: #fffff;
                color: rgb(42, 64, 154);
                border: 1px solid rgb(42, 64, 154);
                width: 90px;
              "
              @click="clickNo()"
              >{{$t("NO")}} </v-btn
            >
          </div>
          <div>
            <v-btn
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 90px;
              "
              @click="clickYes()"
              >{{$t("YES")}} </v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
      <v-overlay :value="showPaginationSpinner">
        <SpinnerComponent></SpinnerComponent>
      </v-overlay>
    </v-dialog>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface";
Vue.use(Toasted, {
  duration: 3000,
  icon : 'check',
  position: "top-right", // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
  theme: "toasted-primary", // ['toasted-primary', 'outline', 'bubble']
  iconPack: "fontawesome", // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
  // className: ['your-custom-class',]
});
export default {
  props: ["confirmationDialogObject"],
  components: {
    MessageDialog,
    SpinnerComponent,
  },
  data() {
    return {
      dialog: false,
      dialogObject: {},
      action: "",
      // showMessageDialog: false,
      // dialogText: "",
      workFlowId: "",
      dialogFileAttachments: [],
      showPaginationSpinner: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessage:"",
      errorMessageObject: {},
      okMessageObject: {}
    };
  },
  methods: {
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    addRecord() {
      var options = {
        applicationId: this.$route.query.applicationId,
        // userId: getFromLocalStorage("userId"),
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        viewId: this.dialogObject.viewId,
        data: this.dialogObject.data,
        additionalFieldInformation : this.dialogObject.additionalFieldInformation,
        workflowId: this.workFlowId,
      };
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/addARecord",
        data: options,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((addRecordResponse) => {
          // this.showMessageDialog = true;
          //this.dialogText = "Record added Successfully";
          // checking for the external embeded dialog
          let isExternalEmbedDialog = getFromLocalStorage("authType")
          if (isExternalEmbedDialog === 'externalEmbed') {
            let recordId = addRecordResponse.data['recordId'];
            let externalEmbedUrl = getFromLocalStorage("externalEmbedUrl");
            // reporting to the client on successfull add record
            window.top.postMessage({ messageType: 'success', recordId }, externalEmbedUrl);
          }
          this.showPaginationSpinner = false;
          this.$emit("closeDialog", false);
          this.$toasted.success("Record added Successfully", {
            icon : "check",
          });
        })
        .catch((addRecordError) => {
          if (addRecordError.response && addRecordError.response.status && addRecordError.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(addRecordError.response && addRecordError.response.data && addRecordError.response.data.errorObject) {
              if(addRecordError.response && addRecordError.response.data && addRecordError.response.data.error){
                  this.errorMessage = addRecordError.response.data.error.join();
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: addRecordError.response.data.errorObject.error_code,
                error_uuid: addRecordError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    updateStage() {
      var options = {
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        data: this.dialogObject.data,
      };
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/changeWorkFlowStatus",
        data: options,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((workFlowUpdateStatus) => {
          this.dialogText = "Status Updated";
          this.showPaginationSpinner = false;
          this.$emit("closeDialog", false);
          this.$toasted.success(this.dialogText,{
            icon : 'check',
          });
        })
        .catch((workFlowUpdateStatusError) => {
          if (workFlowUpdateStatusError.response && workFlowUpdateStatusError.response.status && workFlowUpdateStatusError.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(workFlowUpdateStatusError.response && workFlowUpdateStatusError.response.data && workFlowUpdateStatusError.response.data.errorObject) {
              if(workFlowUpdateStatusError.response && workFlowUpdateStatusError.response.data && workFlowUpdateStatusError.response.data.error){
                  this.errorMessage = workFlowUpdateStatusError.response.data.error.join(', ');
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: workFlowUpdateStatusError.response.data.errorObject.error_code,
                error_uuid: workFlowUpdateStatusError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    updateRecord() {
      var options = {
        applicationId: this.$route.query.applicationId,
        viewId: this.dialogObject.viewId,
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        data: this.dialogObject.data
      };
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/editRecord",
        data: options,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((editRecordSuccess) => {
          this.dialogText = "Record Updated";
          this.showPaginationSpinner = false;
          this.$emit("closeDialog", false);
          this.$toasted.success(this.dialogText,{
            icon : 'check',
          });
        })
        .catch((editRecordError) => {
          if (editRecordError.response && editRecordError.response.status && editRecordError.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(editRecordError.response && editRecordError.response.data && editRecordError.response.data.errorObject) {
              if(editRecordError.response && editRecordError.response.data && editRecordError.response.data.error){
                  this.errorMessage = editRecordError.response.data.error.join();
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: editRecordError.response.data.errorObject.error_code,
                error_uuid: editRecordError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    updateAssignee() {
      var options = {
        applicationId: this.$route.query.applicationId,
        viewId: this.dialogObject.viewId,
        token: getFromLocalStorage("token"),
        authType: getFromLocalStorage("authType"),
        data: this.dialogObject.data        
      };
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/updateAssignee",
        data: options,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((editRecordSuccess) => {
          this.dialogText = "Assignee Updated";
          this.showPaginationSpinner = false;
          this.$emit("closeDialog", false);
          this.$toasted.success(this.dialogText,{
            icon : 'check',
          });
        })
        .catch((editRecordError) => {
          if (editRecordError.response && editRecordError.response.status && editRecordError.response.status == 401) {
            this.$root.$emit('update_session_value', true);
          } else{
            this.errorMessageShow = true;
            if(editRecordError.response && editRecordError.response.data && editRecordError.response.data.errorObject) {
              if(editRecordError.response && editRecordError.response.data && editRecordError.response.data.error){
                  this.errorMessage = editRecordError.response.data.error.join();
              }
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                errorMessage: this.errorMessage,
                error_code: editRecordError.response.data.errorObject.error_code,
                error_uuid: editRecordError.response.data.errorObject.error_uuid,
              };
            } else {
              this.errorMessageObject = {
                errorMessageShow: this.errorMessageShow,
                error_code: "",
                error_uuid: "",
              };
            }
            this.showMessageDialog = true;
          }
        });
    },
    clickYes() {
      this.showPaginationSpinner = true;
      var successFileUploadCount = 0;
      if (this.action === "ADD") {
        if (this.dialogFileAttachments && this.dialogFileAttachments.length === 0) {
          this.addRecord();
        } else {
          for (var attachment in this.dialogFileAttachments) {
            axios
              .post(
                this.dialogFileAttachments[attachment]["url"],
                this.dialogFileAttachments[attachment]["formData"]
              )
              .then((imgUploadSuccess) => {
                successFileUploadCount++;
                if (
                  successFileUploadCount === this.dialogFileAttachments.length
                ) {
                  this.addRecord();
                }
              })
              .catch((imgUploadFailure) => {
                if (imgUploadFailure.response && imgUploadFailure.response.status && imgUploadFailure.response.status == 401) {
                  this.$root.$emit('update_session_value', true);
                } else{
                  this.errorMessageShow = true;
                  this.errorMessageObject = {
                    errorMessageShow: true,
                    error_code: 1601
                  }
                  this.showMessageDialog = true;
                }
              });
          }
        }
      } else if (this.action === "DELETE") {
        var options = {
          applicationId: this.$route.query.applicationId,
          viewId: this.dialogObject.viewId,
          // userId: getFromLocalStorage("userId"),
          token: getFromLocalStorage("token"),
          authType: getFromLocalStorage("authType"),
          data: this.dialogObject.data,
        };
        axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/deleteRecord",
          data: options,
          headers: {
            "content-type": "application/json",
          },
        })
          .then((deleteRecordResponse) => {
            // this.showMessageDialog = true;
            this.showPaginationSpinner = false;
            this.dialogText = "Record Deleted";
            this.$emit("closeDialog", false);
            this.$toasted.success(this.dialogText);
          })
          .catch((deleteRecordError) => {
            if (deleteRecordError.response && deleteRecordError.response.status && deleteRecordError.response.status == 401) {
              this.$root.$emit('update_session_value', true);
            } else{
              this.errorMessageShow = true;
              if(deleteRecordError.response && deleteRecordError.response.data && deleteRecordError.response.data.errorObject) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: deleteRecordError.response.data.errorObject.error_code,
                  error_uuid: deleteRecordError.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      } else if (this.action === "Workflow") {
        if (this.dialogFileAttachments && this.dialogFileAttachments.length === 0) {
          this.updateStage();
        } else {
          for (var attachment in this.dialogFileAttachments) {
            axios
              .post(
                this.dialogFileAttachments[attachment]["url"],
                this.dialogFileAttachments[attachment]["formData"]
              )
              .then((imgUploadSuccess) => {
                successFileUploadCount++;
                if (
                  successFileUploadCount === this.dialogFileAttachments.length
                ) {
                  this.updateStage();
                }
              })
              .catch((imgUploadFailure) => {
                if (imgUploadFailure.response && imgUploadFailure.response.status && imgUploadFailure.response.status == 401) {
                  this.$root.$emit('update_session_value', true);
                } else{
                  this.errorMessageShow = true;
                  this.errorMessageObject = {
                    errorMessageShow: true,
                    error_code: 1602
                  }
                  this.showMessageDialog = true;
                }
              });
          }
        }
      } else if (this.action === "UPDATE") {
        if (this.dialogFileAttachments && this.dialogFileAttachments.length === 0) {
          this.updateRecord();
        } else {
          for (var attachment in this.dialogFileAttachments) {
            axios
              .post(
                this.dialogFileAttachments[attachment]["url"],
                this.dialogFileAttachments[attachment]["formData"]
              )
              .then((imgUploadSuccess) => {
                successFileUploadCount++;
                if (
                  successFileUploadCount === this.dialogFileAttachments.length
                ) {
                  this.updateRecord();
                }
              })
              .catch((imgUploadFailure) => {
                if (imgUploadFailure.response && imgUploadFailure.response.status && imgUploadFailure.response.status == 401) {
                  this.$root.$emit('update_session_value', true);
                } else{
                  this.errorMessageShow = true;
                  this.errorMessageObject = {
                    errorMessageShow: true,
                    error_code: 1603
                  }
                  this.showMessageDialog = true;
                }
              });
          }
        }
      } else if (this.action === "ASSIGNEEUPDATE") {
        this.updateAssignee()
      } else {
        this.showPaginationSpinner = false;
      }
    },
    clickNo() {
      this.dialog = false;
      this.$emit("closeDialog", true);
    },
  },
  mounted() {
    this.dialogObject = this.$props.confirmationDialogObject;
    this.dialogFileAttachments = this.dialogObject.fileAttachments;
    this.dialog = this.dialogObject.display;
    this.action = this.dialogObject.action;
    this.workFlowId = this.dialogObject.workFlowId;
    if(this.dialogFileAttachments && this.dialogFileAttachments.length > 0 && this.action!=="Workflow"){
      this.dialogFileAttachments = this.dialogFileAttachments.filter(attachmentObject => attachmentObject.key in this.dialogObject.data);
    }
    else{
      this.dialogFileAttachments = this.dialogFileAttachments.filter(attachmentObject => attachmentObject.key in this.dialogObject.data.data);
    }
    if(this.dialogObject.display === false){
      this.clickYes();
    }
  },
};
</script>