<template>
  <v-card class="spotlightCard" style="height:100%; width: 100%; border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
    <v-card-title class="" style="background-color:#FF7070; height:4rem;">
      <div class="d-flex flex-row justify-space-between" style="width:100%; max-width:100%; height:4rem; max-height:4rem; margin:0; padding:0;">
        <div style="color:#2A409A; display:flex; flex-direction:row; height:30px; align-items: center; justify-content: space-between;">
          <v-icon style="color:white;" >mdi-information-outline</v-icon> &nbsp; &nbsp; <span style="color:white; "> Error info </span>
        </div>
        <div class="">
          <v-icon
            @click="closeExpendDialod()"
            color="#FF7070"
            style="color: white; cursor: pointer;"
            >mdi-close</v-icon
          >
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text style="overflow-y: scroll; overflow-x: hidden; padding:20px; background-color: rgba(255, 112, 112, 0.1);" >
      <p v-if="this.errorValidationDialogObject.hasOwnProperty('data') && this.errorValidationDialogObject.data.errorList.length !== 0" style="color:#EB5757;">
          {{ this.errorMessageDisplay }}
      </p>
      <ul v-for="error in this.errorDetails">
          <div v-if = "error.type === 'tableError'">
            <div v-if = "error.fieldName">
              <li style="color:black; font-style: Mixed; font-size: 18px; font-size: 18px"> {{ error.fieldName }} </li>
            </div>
            <div v-else>
              <li style="color:black; font-style: Mixed; font-size: 18px;  margin-left: 40px" > Row Number {{ error.row   }} - {{  error.columnName }} </li>
          </div>
          </div>
          <div v-else>
            <li style="color:black; font-style: Mixed; font-size: 18px"> {{ error }} </li>
          </div>
      </ul>
      <br/>
      <p v-if="Object.keys(this.errorValidationDialogObject) && (this.errorValidationDialogObject.valueValidationErrorList.length !== 0 || this.errorValidationDialogObject.tableValidationErrorList.length !== 0)  " style="color:#EB5757;">
        Value Mismatch :
      </p>
      <ul v-for="mismatch in this.valueValidationArray">
        <li style="color:black; font-style: Mixed; font-size: 18px"> {{mismatch}} </li>
      </ul>
      <div v-for="tableError in Object.values(this.tableValidationErrorList)">
        <ul v-for="(errors,tableName) in tableError ">
          <li style="color:black; font-style: Mixed; font-size: 18px; font-size: 18px"> Table: <span class="font-semibold"> {{ tableName }} </span> </li>
            <ul v-for="(errorList,typeError) in errors ">
              <li v-if="typeError == 'optionError'" style="color:black; font-style: Mixed; font-size: 18px; font-size: 18px">Please select value from the options provided for</li>
              <li v-else-if="typeError == 'futureDateError'" style="color:black; font-style: Mixed; font-size: 18px; font-size: 18px">Please refrain from selecting future dates for</li>
              <li v-else-if="typeError == 'pastDateError'" style="color:black; font-style: Mixed; font-size: 18px; font-size: 18px">Please refrain from selecting past dates for</li>
              <li v-else-if="typeError == 'numberRangeErrorList'" style="color:black; font-style: Mixed; font-size: 18px; font-size: 18px">Please add numbers within the given range for</li>
              <ul v-for="error in errorList">
                  <li style="color:black; font-style: Mixed; font-size: 18px; " > Row Number {{ error.row   }} - {{  error.columnName }} </li>
              </ul>
           </ul>
        </ul>
      </div>
      <template v-if="isMMSecondarySupportLang">
        <br/>
      <br/>
      <p v-if="this.errorValidationDialogObject.hasOwnProperty('data') && this.errorValidationDialogObject.data.errorList.length !== 0" style="color:#EB5757;">
          {{ this.errorMessageDisplayInMM }}
      </p>
      <ul v-for="(error,index) in this.errorDetailsInMM" :key="index">
          <li style="color:black; font-style: Mixed; font-size: 18px"> {{ error }} </li>
      </ul>
      <br/>
      <p v-if="Object.keys(this.errorValidationDialogObject) && this.errorValidationDialogObject.valueValidationErrorList.length !== 0 " style="color:#EB5757;">
        Value Mismatch :
      </p>
      <ul v-for="(mismatch, index) in this.valueValidationArrayInMM" :key="index">
          <li style="color:black; font-style: Mixed; font-size: 18px"> {{mismatch}} </li>
      </ul>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
import { getFromLocalStorage } from '../store/localStorageInterface';
Vue.use(Toasted, {
  duration: 3000,
  position: "top-right", // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
  theme: "toasted-primary", // ['toasted-primary', 'outline', 'bubble']
  iconPack: "fontawesome", // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
});
export default {
  props: ["errorValidationDialogObject"],
  components: {
    SpinnerComponent,
    closeExpend:false
  },
  data() {
    return {
      dialog: false,
      dialogObject: {},
      showMessageDialog: false,
      dialogFileAttachments: [],
      showPaginationSpinner: false,
      isMMSecondarySupportLang: false,
      errorMessageDisplayInMM:'',
      valueValidationArrayInMM:[],
      errorDetailsInMM:[],
      errorDetails:[],
      errorMessageDisplay:'',
      valueValidation:'',
      valueValidationArray:[],
      tableValidationErrorList: [],
    };
  },
  watch: {
    "errorDetails" : {
      handler: function () {
        this.errorDetails = this.errorValidationDialogObject.data.errorList;
      } 
    }
  },
  methods: {
    closeExpendDialod(){
      this.closeExpend = true
      this.closeExpendEmit();
    },
    closeExpendEmit(){
      this.$emit('closeErrorExpend', this.closeExpend)
    },
  },
  mounted() { 
      this.errorMessageDisplay = this.errorValidationDialogObject.displayText
      this.errorDetails=this.errorValidationDialogObject.data.errorList;
      this.valueValidationArray = this.errorValidationDialogObject.valueValidationErrorList;
      this.tableValidationErrorList = this.errorValidationDialogObject.tableValidationErrorList;
      let secondarySupportLanguageDetails = getFromLocalStorage('secondarySupportLanguage');
      if (secondarySupportLanguageDetails && secondarySupportLanguageDetails.secondarySupportLanguage === 'Burmese' && secondarySupportLanguageDetails.applicationId == this.$route.query.applicationId) {
        this.isMMSecondarySupportLang = true; 
        this.errorMessageDisplayInMM = this.errorValidationDialogObject.displayTextInMM
        this.errorDetailsInMM=this.errorValidationDialogObject.data.errorListInMM;
        this.valueValidationArrayInMM = this.errorValidationDialogObject.valueValidationErrorListInMM
      }
  },
};
</script>
<style scoped>
.frameContainer {
  display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;
}
.spotlightIframe{
  flex-grow: 1; border: none; margin: 0; padding: 0; 
}
.spotlightCard.v-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  transition: width 5s;
}

.spotlightCard.v-card > .v-card__text{
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex: 1 1 auto;
  overflow-y: auto;
}
.spotlightCard.v-card > .v-card__actions, .spotlightCard.v-card > .v-card__title {
  flex: 0 0 auto;
}
</style>
<style >

.spotlightDialog{
  height:90%
}
</style>