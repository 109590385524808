<template>
  <div>
    <div class="timePicker" style="margin-bottom:30px; align-items:; justify-content: space-between;">
      <VueMaterialDateTimePicker variant="standard" style="
        border:1px solid gray;  
        border-radius:5px; 
        background-color:#f0f0f0; 
        height:60px; 
        width:60%; 
        display: flex; 
        justify-content: center; 
        align-items: center;" class="datepicker" @input="dateEmit" v-model="DateTime" :disabled-dates-and-times="disabledDatesAndTime" :is-date-only="false" />
      <div class="timepickercomp">
        <div class="timezonecomp" style="padding-top:-10px; margin-top: -10px; margin-bottom: 10px; " >Time zone</div>
        <div class="v-btn-timezone" style="padding-left: 10px; padding-right: 10px; display: flex; align-items: center; justify-content: center; border:1px solid gray; border-radius:5px; background-color:#f0f0f0; height:60px; width:100%; color: gray;" disabled>{{this.alertDetails.alertConfiguration.timezone}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import VueMaterialDateTimePicker from 'vue-material-date-time-picker'

export default {
  props: ["alertDetails",'value'],
  components: {
    VueMaterialDateTimePicker
  },
  data() {
      return {
        DateTime: new Date(),
        epochDateTime: 0,
        pdate:new Date(),
        disabledDatesAndTime: {
        to: Date.now()
        },
      };
  },
  methods:{
    formatValue(){
      this.pdate = this.DateTime
      var s = new Date(this.DateTime).toLocaleString(undefined, {timeZone: this.alertDetails.alertConfiguration.timezone});
      this.DateTime = s
    },
    dateEmit(){
      this.formatValue(this.DateTime);
      this.epochDateTime =  Date.parse(this.pdate) ;
      this.$emit('input', this.epochDateTime);
    }
  },
  mounted() {
    this.epochDateTime = this.value;
    this.DateTime = new Date(this.epochDateTime);
    if(this.DateTime=="Invalid Date"){
      this.DateTime = ''
    }else{
      this.formatValue(this.DateTime);
    }
  }
}
</script>
<style scoped>
.datepicker{
  width: 70%;
  border: none;
}
.timepickercomp{
  margin-top: -1.4rem;
}
  .timePicker{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .v-btn-timezone{
    font-size: 1rem;
    background-color: aliceblue;
    border-radius: 10px ;
  }
  .timePicker>>>input{
    border: none;
    z-index:10;
  }
  .timePicker>>>input:focus{
    display: none
  }

  .timezonecomp{
    margin:0rem 4rem 0rem 0rem;
  }

</style>

<style>
  VueMaterialDateTimePicker{
    border: none;
  }
</style>