<template>
  <div>
    <v-file-input
      outlined
      background-color="#F2F2F2"
      prepend-icon=""
      append-icon="mdi-paperclip"
      show-size
      v-model="files"
      @change="fileChange()"
      @click:clear="$emit('revertOldFile',fieldKey)"
      ></v-file-input>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import MessageDialog from "./MessageDialog.vue";
import {getFromLocalStorage} from "../store/localStorageInterface"
import moment from 'moment';
export default {
  props: ["fieldKey", "fileDefinition", "workFlowId", "viewid"],
  components: {
    MessageDialog,
  },
  data() {
    return {
      fileDef: {},
      files: [],
      showMessageDialog: false,
      viewId:"",
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
    };
  },
  methods: {
    fileChange() {
      if (this.$data.files) {
        if (this.$data.files.name) {
          var date = new Date();
          var newDate = new Date(date).getTime();
          let currentfileName = this.$data.files.name;
          // var fileName = uuidv4();
          let fileType = this.$data.files.name.split('.').pop();
 					let fileName = this.$data.files.name.split('.').slice(0, -1).join('.');
 				  fileName = fileName.replace(/[^\w\s]/gi, '');
 				  fileName = fileName.replace(/\s+/g, '_');
 				  fileName = fileName.replace(/[^a-zA-Z0-9_]/g, '');
 				  fileName = fileName + "_" +moment().valueOf();
          var activeFile = this.$data.files;
          var mimeType = activeFile.type;
          let file_size = activeFile.size;
          // if(mimeType == "" && this.$data.files.name.split('.').pop() == "docx"){
          if(mimeType == "" && fileType == "docx"){
            mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          }
          var applicationId = this.$route.query.applicationId;
          var workFlowId = this.workFlowId;
          //   appID/workflowId/createdByUsername/epoch_filename
          var folderUrl = `${applicationId}/${workFlowId}/${fileName}`;

          var getPreSignedURLBody = {
            file_name: folderUrl,
            file_type: mimeType,
            file_size: file_size,
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
            applicationId: this.$route.query.applicationId, 
            workFlowId: this.workFlowId, 
            viewId: this.viewId,
            key: this.fieldKey
          };

          axios
            .post(
              process.env.VUE_APP_BACKEND_URL + "/getPresignedURLWrite",
              getPreSignedURLBody
            )
            .then((getPresignedURLSuccess) => {
              var fields = getPresignedURLSuccess.data.data.fields;
              var url = getPresignedURLSuccess.data.data.url;
              var post_body = {
                bucket: fields.bucket,
                ...fields,
                "Content-Type": mimeType,
                file: activeFile,
              };
              const formData = new FormData();
              for (const name in post_body) {
                formData.append(name, post_body[name]);
              }
              var objectValue = {
                status:true,
                url: url,
                formData: formData,
                key: this.$props.fieldKey,
                file: folderUrl,
                currentfileName : currentfileName
              };
              this.$emit("getBase64", objectValue);
            })
            .catch((getPresignedURLError) => {
              this.$data.files = null;
              var objectValue = {
                status : false,
                key: this.$props.fieldKey
              };
              this.$emit("getBase64", objectValue);
              this.$refs.fileupload = null;
              if (
                getPresignedURLError.response &&
                getPresignedURLError.response.status &&
                getPresignedURLError.response.status == 401
              ) {
                this.$root.$emit('update_session_value', true);
              }
              else{
                this.errorMessageShow = true;
                if(getPresignedURLError.response && getPresignedURLError.response.data && getPresignedURLError.response.data.errorObject){
                  this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: getPresignedURLError.response.data.errorObject.error_code,
                  error_uuid: getPresignedURLError.response.data.errorObject.error_uuid,
                };
                }
                else{
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                };
                }
              this.showMessageDialog = true;
              }
            });
        } else {
          this.$data.files = null;
          this.$refs.fileupload = null
        }
      } else {
        var objectValue = {
          status : false,
          key: this.$props.fieldKey
        };
        this.$emit("getBase64", objectValue);
        this.$data.files = null;
        this.$refs.fileupload = null
      }
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
  },
  mounted() {
    this.fileDef = this.$props.fileDefinition;
    this.viewId = this.viewid;
    this.fieldKey = this.fieldKey;
    this.workFlowId = this.workFlowId;
  },
};
</script>