<template>
  <div>
      <v-dialog v-model="dialogError" persistent width="801px" style="box-shadow: none;">
      <v-card style="border-radius: 10px; " >
        <v-container style="margin: 0px !important;padding: 0% !important;">
          <div class="dialogContainer" style="display:flex !important; justify-content:space-between;">
            <div class="flex1">
              <span>
                <v-icon class="rounded-xl" size="28px" color="#FF7070" style=" height: 36.67px; ">mdi-information-outline</v-icon>
                <span
                  style = "
                    color: #FF7070;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 0.02em"
                  >
                  Error info
                </span>
              </span>
            </div>
          </div>
        </v-container>
        <v-divider  style="margin:0px 30px !important;" />
        <v-card-text class="contentCard" style="padding: 0px 30px !important; ">
          <div class="grid-1">
            <h1 class="errorHeading">Ooops...</h1>
            <p class="errorDetails" >
              <template
                v-if="
                this.errorValidationDialogObject &&
                this.errorValidationDialogObject.data &&
                this.errorValidationDialogObject.data.errorList &&
                this.errorValidationDialogObject.data.errorList.length > 0"
                >
              </template>
            </p>
            <p class="errorDetails" >
              <template v-if="valueValidationErrorList && valueValidationErrorList.length > 0">
                <span style="font-weight: 600">Value Mismatch:</span>
                <ul style="list-style-type: none; margin-top: 5px; padding-left: 0;">
                  <li v-for="item in valueValidationErrorList" :key="item" style="margin-bottom: 5px;">
                    <span style="color: red; font-size: 20px; line-height: 1.5;">&bull;</span> <span>{{ item }}</span>
                  </li>
                </ul>
              </template>
            </p>  
          </div>
          <div class="grid-2">
            <v-img class="cardimage"
              :src="require('../assets/ErrorImage.png')"
              contain/>
          </div>
        </v-card-text>
        <v-card-actions style="padding: 0px 0px 30px 30px">
          <v-btn
            elevation="0"
            style="background-color: #BBBCBD !important;
            text-transform: unset !important;
            color: #ffffff;
            font-family: 'Poppins';
            font-weight: 600;
            width: 94px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            padding: 16px; 
            border-radius: 6px;           
            "
            @click="clickOk()"
            >Close 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["displayErrorValidationDialog", "errorValidationDialogObject"],
  data() {
    return {
      dialogError: false,
      displayText: "",
      text: "",
      valueValidationErrorList: [],
      valueMismatch: false,
    };
  },
  methods: {
    clickOk() {
      this.$emit("closeErrorValidationDialog", false);
    },
  },
  mounted() {
    this.dialogError = this.$props.displayErrorValidationDialog;
    var errorValidationDialogObject = this.$props.errorValidationDialogObject;
    this.displayText = errorValidationDialogObject.displayText;
    this.text = errorValidationDialogObject.data.errorList.join(', ');
    this.valueValidationErrorList =
      errorValidationDialogObject.valueValidationErrorList;
    if (this.valueValidationErrorList && this.valueValidationErrorList.length > 0) {
      this.valueMismatch = true;
    }
  },
};
</script>
<style scoped>
.icon {
  margin-left: 45%;
}
/*error Container styles */
.dialogContainer{
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 30px;
  flex-direction: row !important;
}

.errorHeading{
  margin-top: 18px;
  font-size: 42px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: 0.02em;
}
.copybtn{
  background-color: #FF5B66 !important;
  text-transform: unset !important;
  color: #ffffff !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-left: 5px;
  letter-spacing: 0.02em;
  padding: 16px; 
  border-radius: 3px;
}
.contentCard{
  font-family: 'Poppins';
  display: grid !important;
  grid-template-columns: repeat(2,1fr) !important;
  grid-auto-rows: minmax(100px,auto) !important;
  margin: 0px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  text-align: left;
}
.grid-1{
  padding-bottom: 25px;
  grid-column: 1;
  grid-row: 1/2;
}
.grid-2{
  grid-column: 2;
  grid-row: 1/2;
  margin: 0px !important;
}

.grid-2 .cardimage{
  height: 270px;
  width: 381px;
}
.errorCode{
font-size: 33px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0.02em;
margin-top: 10px;
text-align: left;
}
.errorDetails{
font-size: 15px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0.02em;
text-transform: unset !important;
margin-top: 10px;
}

@media (max-width:700px) {
  .dialogContainer{
    flex-direction: column !important;
    margin-right: 10px !important;

  }
  .contentCard{
    grid-template-columns: repeat(1,1fr) !important;
  grid-auto-rows: minmax(100px,auto) !important;
  }
.grid-1{
  grid-column: 1;
  grid-row: 1/2;
  padding-bottom: 0px !important;
}
.grid-2{
  grid-column: 1;
  grid-row: 2/3;
  margin-bottom: 20px !important;
}

  .grid-2 .cardimage{
  height: 230px;
  width: 285px;
  }
}
</style>