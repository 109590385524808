<template>
  <v-card class="spotlightCard" style="height:100%; width: 100%; border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
    <v-card-title class="py-3">
      <div class="d-flex flex-row justify-space-between" style="width: 100%;max-width:100%">
        <div style="color:#2A409A">
          {{dialogObject.spotlightTitle}}
        </div>
        <div class="mr-2">
          <v-icon
            color="#FF7070"
            style=""
            @click="close()"
            >mdi-close</v-icon
          >
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text 
      style="overflow-y: hidden; overflow-x: hidden;padding:0"
    >
      <div class="frameContainer">
        <iframe class="spotlightIframe" allow="clipboard-write" :src="dialogObject.url"></iframe>
      </div>
    </v-card-text>
    <!-- <v-divider /> -->
    <!-- <v-card-actions>
      
    </v-card-actions> -->
  </v-card>
</template>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
Vue.use(Toasted, {
  duration: 3000,
  position: "top-right", // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
  theme: "toasted-primary", // ['toasted-primary', 'outline', 'bubble']
  iconPack: "fontawesome", // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
});
export default {
  props: ["spotlightDialogObject"],
  components: {
    SpinnerComponent,
  },
  data() {
    return {
      dialog: false,
      dialogObject: {},
      showMessageDialog: false,
      dialogFileAttachments: [],
      showPaginationSpinner: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("closeSpotlightDialog");
    },
  },
  mounted() {
    this.dialogObject = this.$props.spotlightDialogObject;
    let params = new URLSearchParams({
      token: this.dialogObject.token,
      authType: this.dialogObject.authType,
      applicationID: this.dialogObject.appID
    });
    params.append(this.dialogObject.queryParamKey, this.dialogObject.queryParamValue);
    this.dialogObject.url = this.dialogObject.spotlightURL + "?"+ params.toString();
    this.dialog = this.dialogObject.display;
  },
};
</script>
<style scoped>
.frameContainer {
  display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;
}
.spotlightIframe{
  flex-grow: 1; border: none; margin: 0; padding: 0; 
}
.spotlightCard.v-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  transition: width 5s;
}

.spotlightCard.v-card > .v-card__text{
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex: 1 1 auto;
  overflow-y: auto;
}
.spotlightCard.v-card > .v-card__actions, .spotlightCard.v-card > .v-card__title {
  flex: 0 0 auto;
}
</style>
<style >

.spotlightDialog{
  height:90%
}
</style>