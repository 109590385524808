<template>
  <div>
    <v-file-input
	  v-model="currFiles"
	  small-chips
	  background-color="#F2F2F2"
	  prepend-icon=""
	  append-icon="mdi mdi-file-upload"
	  show-size
	  multiple
	  :clearable="false"
	  outlined
	  @change="inputChanged"
	  placeholder="upload files here"
	>
	<template v-slot:selection="{ text, index, file }">
	</template>
	</v-file-input>
	<div v-if="files && files.length" style="display: flex; flex-wrap:wrap; flex-direction: row; margin-bottom: 8px">
	  <v-chip 
		  v-for="(file,index) in this.filteredFiles" 
		  :key="index"
		  class="custom-chip"
		  :title="file.name"
		  >
		  <span class="ellipsis-text">{{ fileNameFormat(file.name) }}</span>
		  <v-icon 
		  class="close-icon"
		  @click="remove(index,file.name)"
		  >mdi-close-circle</v-icon>
	  </v-chip>
	</div>
	<div v-if="showMessageDialog">
	  <MessageDialog
		:displayDialog="showMessageDialog"
		:messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
		@closeMessageDialog="closeMessageDialog"
	  ></MessageDialog>
	</div>
	<v-overlay :value="showSpinner">
		<SpinnerComponent></SpinnerComponent>
	  </v-overlay>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import SpinnerComponent from "./SpinnerComponent.vue";
import MessageDialog from "./MessageDialog.vue";
import { formatFileNameMixin } from "../mixins/formatFileName.js";
import {getFromLocalStorage} from "../store/localStorageInterface";
import moment from 'moment';
export default {
  props: ["fieldKey", "fileDefinition", "workFlowId", "viewid","showFileList","showFields"],
  components: {
    MessageDialog,
	SpinnerComponent
  },
  mixins: [formatFileNameMixin],
  data() {
	  return{
		currFiles: [],
		files: [],
		filteredFiles:[],
		fileDef:{},
		viewId:"",
		uniqueFiles:[],
		Allfiles:[],
		showSpinner: false,
		fileAttachments:[],
		showMessageDialog: false,
		fileObjectArray:[]
	  }
	},
	methods: {
		remove(index,name) {
		  this.filteredFiles.splice(index, 1);
		  var position=this.uniqueFiles.indexOf(name);
		  this.uniqueFiles.splice(position, 1);
		  this.files=this.filteredFiles;
		  this.currFiles= this.filteredFiles;
		  this.$emit("multiFileRemove", name,this.$props.fieldKey);
		}, 
		inputChanged() {
		  if(this.currFiles.length>0){
		  	this.files = [
		  	  ...this.currFiles,
		  	  ...this.files
		  	]
		  	var uniqueIds = new Set();
		  	this.filteredFiles = this.files.filter(element => {
		  	const isDuplicate = uniqueIds.has(element.name);
		  	uniqueIds.add(element.name);
		  	if (!isDuplicate) {
		  		return true;
		  	}
		  	return false;
		  	});
		  	this.validateFiles();
		  }
		},
		async validateFiles(){
		  this.showSpinner =  true
		  for(var file of this.filteredFiles){
			  if(file.name){
				  if(this.uniqueFiles && !this.uniqueFiles.includes(file.name)){
					  this.uniqueFiles.push(file.name);
					  var date = new Date();
					  var newDate = new Date(date).getTime();
					//   var fileName = uuidv4();
					  let fileType = file.name.split('.').pop();
 					  let fileName = file.name.split('.').slice(0, -1).join('.');
 					  fileName = fileName.replace(/[^\w\s]/gi, '');
 					  fileName = fileName.replace(/\s+/g, '_');
 					  fileName = fileName.replace(/[^a-zA-Z0-9_]/g, '');
 					  fileName = fileName + "_" +moment().valueOf();
					  var activeFile = file;
					  var mimeType = activeFile.type;
					  let file_size = activeFile.size;
					//   if(mimeType == "" && file.name.split('.').pop() == "docx"){
					if(mimeType == "" && fileType == "docx"){
					  	mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
					  }
					  var applicationId = this.$route.query.applicationId;
					  var workFlowId = this.workFlowId;
					  var folderUrl = `${applicationId}/${workFlowId}/${fileName}`;
					  var getPreSignedURLBody = {
					  	file_name: folderUrl,
					  	file_type: mimeType,
					  	file_size: file_size,
					  	token: getFromLocalStorage("token"),
					  	authType: getFromLocalStorage("authType"),
					  	applicationId: this.$route.query.applicationId, 
					  	workFlowId: this.workFlowId, 
					  	viewId: this.viewId,
					  	key: this.fieldKey
					  };
					  await axios
					  	.post(
					  		process.env.VUE_APP_BACKEND_URL + "/getPresignedURLWrite",
					  		getPreSignedURLBody
					  	).then((getPresignedURLSuccess) => {
					  		var fields = getPresignedURLSuccess.data.data.fields;
					  		var url = getPresignedURLSuccess.data.data.url;
					  		var post_body = {
					  			bucket: fields.bucket,
					  			...fields,
					  			"Content-Type": mimeType,
					  			file: activeFile,
					  		};
					  		var formData = new FormData();
					  		for(var ind in post_body){
								formData.append(ind, post_body[ind]);
					  		}
					  		var objectValue = {
					  			status:true,
					  			url: url,
					  			formData: formData,
					  			key: this.$props.fieldKey,
								currentfileName : file.name,
					  			file: getPresignedURLSuccess.data.data.fields.key,
					  		};
					  		this.$emit("multifileCheck", objectValue);
					  		this.fileAttachments.push(objectValue);
					  	})
					  	.catch((getPresignedURLError) => {
							this.files.splice(0,1);
							this.filteredFiles.splice(0,1);
					  	    this.uniqueFiles=[];
						  	this.currFiles = [];
					  	  var objectValue = {
					  	  	status : false,
					  	  	key: this.$props.fieldKey
					  	  };
					  	  this.$emit("multifileCheck", objectValue);
					  	  if (
					  	  	getPresignedURLError.response &&
					  	  	getPresignedURLError.response.status &&
					  	  	getPresignedURLError.response.status == 401
					  	  ) {
					  	  	this.$root.$emit('update_session_value', true);
					  	  }
					  	  else{
					  	  	this.errorMessageShow = true;
					  	  	if(getPresignedURLError.response && getPresignedURLError.response.data && getPresignedURLError.response.data.errorObject){
					  	  		this.errorMessageObject = {
					  	  		errorMessageShow: this.errorMessageShow,
					  	  		error_code: getPresignedURLError.response.data.errorObject.error_code,
					  	  		error_uuid: getPresignedURLError.response.data.errorObject.error_uuid,
					  	  	 };
					  	  	}
					  	  	else{
					  	  	  this.errorMessageObject = {
					  	  	  	errorMessageShow: this.errorMessageShow,
					  	  	  	error_code: "",
					  	  	  	error_uuid: "",
					  	  	  };
					  	  	}
							this.showSpinner =  false
					  	  	this.showMessageDialog = true;
					  	  }
					  	});
					}
				}
				else {
					this.this.filteredFiles = [];
          			this.files = [];
					this.currFiles=[];
					this.uniqueFiles = [];
				}
			}
			this.showSpinner =  false
		},
		closeMessageDialog(emitObject) {
		  var closeDialogFlag = false;
		  if(emitObject && emitObject.errorDisplayMessage){
		  	closeDialogFlag = emitObject.errorDisplayMessage
		  }
		  this.showMessageDialog = emitObject.flag;
		  this.$emit("closeDialog", closeDialogFlag);
		}
	},
	mounted() {
	  this.fileDef = this.$props.fileDefinition;
	  this.viewId = this.viewid;
	  this.fieldKey = this.fieldKey;
	  this.workFlowId = this.workFlowId;
	}
}
</script>
<style scoped>
.custom-chip {
  display: flex;
  align-items: center;
  width: 230px;
  margin: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-chip .ellipsis-text {
  flex: 1;
}

.custom-chip .close-icon {
  margin-left: 5px;
  font-size: 16px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .custom-chip {
    width: 150px;
    margin: 3px;
    font-size: 12px;
  }

  .custom-chip .ellipsis-text {
    max-width: 100px;
  }

  .custom-chip .close-icon {
    margin-left: 3px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .custom-chip {
    width: 120px;
    margin: 2px;
    font-size: 10px;
  }

  .custom-chip .ellipsis-text {
    max-width: 80px;
  }

  .custom-chip .close-icon {
    margin-left: 2px;
    font-size: 12px;
  }
}


</style>
