<template>
  <v-container fluid class="input_field_div">
    <v-row class="pr-0">
      <v-col cols="12" sm="2" class="pl-0">
        <v-select
          :items="langitems"
          placeholder="Lang"
          outlined
          filled
          v-model="langSelected"
          :value="langSelected"
          @change="refreshLangITems()"
          hide-details
          :disabled="selectedNrcLangEnabled || disable"
        ></v-select>
      </v-col>
      <v-col style="padding: 5%; margin-right: 19%; color: #0054a6">
        <div>NRC : {{ nrc }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2" class="pl-0 pr-1">
        <v-select
          :items="districtitems"
          placeholder="District"
          outlined
          filled
          :value="districtSelected"
          v-model="districtSelected"
          @change="getDistrictViseData()"
          :disabled="disable"
        ></v-select>
      </v-col>
      <b class="pt-6">/</b>
      <v-col cols="12" sm="3" class="pl-1" style="max-width: 30%; !important;">
        <v-select
          :items="townships"
          v-model="townshipSelected"
          item-value="townshipSelected"
          item-text="townshipSelected"
          placeholder="townships"
          outlined
          @change="concatData()"
          filled
          :disabled="disable"
        ></v-select>
      </v-col>
      <b class="pt-6">(</b>
      <v-col
        cols="6"
        sm="1"
        class="pl-0"
        style="max-width: 22.333%; !important;margin-left:8px"
      >
        <v-select
          :items="letterItems"
          v-model="letterSelected"
          item-value="letterSelected"
          item-text="letterSelected"
          @change="concatData()"
          outlined
          filled
          :disabled="disable"
        ></v-select>
      </v-col>
      <b class="pl-0 pt-6">) </b>
      <v-col cols="12" sm="3" class="pr-0" style="flex: 0 0 20.6%; !important">
        <v-text-field
          class="pr-0"
          outlined
          background-color="#F2F2F2"
          placeholder="Number"
          v-model="nrcNumber"
          @focusout="concatData()"
          :rules="[rules.required, rules.counter]"
          counter="6"
          :disabled="
            (langSelected == '' ||
            districtSelected == '' ||
            townshipSelected == '') || disable
          "
        ></v-text-field>
      </v-col>
    </v-row>
    <div>
      <div v-if="showMessageDialog">
        <MessageDialog
          :displayDialog="showMessageDialog"
          :messageObject="
            errorMessageShow ? errorMessageObject : okMessageObject
          "
          @closeMessageDialog="closeMessageDialog"
        ></MessageDialog>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { getFromLocalStorage } from "../store/localStorageInterface";
import MessageDialog from "./MessageDialog.vue";
export default {
  props: ["value", "nrcConfig", "toDisable"],
  components: {
    MessageDialog,
  },
  data() {
    return {
      langitems: ["en", "mm"],
      langSelected: "en",
      items: {},
      districtitems: [],
      districtSelected: "",
      districts: {},
      townships: [],
      townshipSelected: "",
      Letters: {},
      letterItems: [],
      letterSelected: "",
      nrcNumber: "",
      nrc: this.value,
      rules: {
        required: (value) => !!value || "",
        counter: (v) => (v.length <= 6 && v.length >= 5) || "",
      },
      selectedNrcLangEnabled: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      disable: false
    };
  },
  methods: {
    getDistrictViseData() {
      this.townships = this.districts[this.districtSelected];
      this.concatData();
    },
    getLangItems() {
      this.districtitems = [];
      this.townships = [];
      if (this.langSelected === "en") {
        this.districtitems = this.items.districtitemsEng;
        this.letterItems = this.Letters.engLetters;
      } else {
        this.districtitems = this.items.districtitemsMML;
        this.letterItems = this.Letters.mmLetters;
      }
      this.concatData();
    },
    refreshLangITems() {
      this.getLangItems();
      this.districtSelected = "";
      this.townshipSelected = "";
      this.letterSelected = "";
      this.nrcNumber = "";
      this.nrc = "";
    },
    setValue() {
      this.getLangItems();
      this.getDistrictViseData();
    },
    concatData() {
      this.nrc =
        this.districtSelected +
        "/" +
        this.townshipSelected +
        "(" +
        this.letterSelected +
        ")" +
        "" +
        this.nrcNumber;
      this.emitInput();
    },
    emitInput() {
      this.$emit("input", this.nrc);
    },
  },
  mounted() {
    if(this.$props.toDisable){
      this.disable = true;
    }
    if (this.$props.nrcConfig && this.$props.nrcConfig.selectedNrcLanguage) {
      if (this.$props.nrcConfig.selectedNrcLanguage == "en" || "mm") {
        this.selectedNrcLangEnabled = true;
        this.langSelected = this.$props.nrcConfig.selectedNrcLanguage;
        this.refreshLangITems()
      }
    }
    var nrcSplit = this.value;
    if (nrcSplit && nrcSplit.length > 0) {
      var nrcChars = nrcSplit.split("/");
      this.districtSelected = nrcChars[0];
      if (this.districtSelected > 0 || this.districtSelected <= 14) {
        this.langSelected = "en";
      } else {
        this.langSelected = "mm";
      }
      this.townshipSelected = nrcChars[1].split("(")[0];
      this.nrcNumber = nrcChars[1].split(")").pop();
      this.letterSelected = nrcChars[1].split(")")[0].split("(").pop();
    }
    var file_name = "common/nrc_common_file.json";
    var read_body = {
      token: getFromLocalStorage("token"),
      authType: getFromLocalStorage("authType"),
      applicationId: this.$route.query.applicationId,
      userId: this.$route.query.userId,
      file_name: file_name,
    };
    axios
      .post(process.env.VUE_APP_BACKEND_URL + "/getPresignedURLRead", read_body)
      .then((readSuccess) => {
        axios
          .get(readSuccess.data.data)
          .then((FileReadResponse) => {
            this.items = FileReadResponse.data.items;
            this.districts = FileReadResponse.data.districts;
            this.townships = this.districts[this.districtSelected];
            this.Letters = FileReadResponse.data.Letters;
            this.setValue();
          })
          .catch((err) => {});
      })
      .catch((readError) => {
        if (
          readError.response &&
          readError.response.status &&
          readError.response.status == 401
        ) {
          this.$root.$emit("update_session_value", true);
        } else {
          this.errorMessageShow = true;
          if (
            readError.response &&
            readError.response.data &&
            readError.response.data.errorObject
          ) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: readError.response.data.errorObject.error_code,
              error_uuid: readError.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
        }
      });
  },
};
</script>

<style>
.input_field_div {
  /* width: 600px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 1px;
  color: #4f4f4f;
  /* border: 1px solid;
  padding: 8px;
  margin-top: auto;
  margin-bottom: auto; */
}
</style>
