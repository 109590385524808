export const labelStyleMixin = {
    inheritAttrs: false,
    methods: {
        getLabelStyle(fieldItem){
            let fontSize = '';
            let backgroundColor = '';
            if(fieldItem['label_configuration']){
              if (fieldItem['label_configuration']['fontSize'] === 'large') {
                fontSize = '18px';
              } else if (fieldItem['label_configuration']['fontSize'] === 'medium') {
                fontSize = '16px';
              } else if (fieldItem['label_configuration']['fontSize'] === 'small') {
                fontSize = '14px';
              } else {
                fontSize = '18px';
              }
              if (fieldItem['label_configuration']['backgroundColor']) {
                backgroundColor =fieldItem['label_configuration']['backgroundColor'];
              } else {
                backgroundColor = '#eeeeee';
              }
            } else {
              fontSize = '18px';
              backgroundColor = '#eeeeee';
            }
            return {
              backgroundColor: backgroundColor,
              color: "#2a409a",
              fontWeight: "500",
              fontSize: fontSize,
              margin: "-8px -30px",
              paddingTop: "8px"
            }
        }
    }
}