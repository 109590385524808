<template>
    <div style="margin: 0px 8px 12px 8px;">
        <vue-excel-editor v-model="data"
            v-if = "mode !== 'VIEW'"
            :new-if-bottom="newRow"
            :no-footer="noFooter" 
            no-header-edit 
            disable-panel-setting 
            disable-panel-filter 
            @update="sendTableData">
            <vue-excel-column v-for="column in columnNames" 
                :field="column.name" 
                :label="column.validators && column.validators.required && !checkAccess(column.access, 'editColumns') ? `<span>${column.name}<span style='color: red'>*</span></span>` : column.name"
                :type="column.type" 
                :options="column.option_values"
                :readonly="(mode == 'UPDATE' || mode == 'ADD' || mode == 'STAGEUPDATE') && workFlowId != '' ? checkAccess(column.access, 'editColumns') : readOnly"
                :width="column.width ? column.width : getWidth(column.name)" 
                :validate="checkNewRow"
                key-field>
            </vue-excel-column>
        </vue-excel-editor>
        <div v-if="Object.keys(summary).length > 0 && mode !== 'VIEW'" style="margin-top: 1%;">
            <ul>
                <li v-for="(value, key) in summary" :key="key">
                    Sum of {{ key }}: {{ value }}
                </li>
            </ul>
        </div>

        <div class="tableComponentDataTable">
            <v-data-table
                v-if="mode === 'VIEW'"
                :headers="dataTableHeaders"
                fixed-header
                max-height="200px"
                :items="data"
                class="elevation-0"
                disable-pagination
                hide-default-footer
                dense
                item-key="index"
            >
              <template v-if="changeDetected" v-for="header in dataTableHeaders" v-slot:[`item.${header.value}`]="{ item, index }">
                    <td :class="isAdded ? 'highlight_add' : isDelete ? 'highlight_delete' : getHighlightClass(item, header.value, index)">
                        {{ item[header.value] }}
                    </td>
              </template>
            </v-data-table>
        </div>
    </div>
</template>


<script>
export default {
    props: ["value", "showFooter", "oldItems", "dataItems", "tableConfiguration", "addNewRow", "mode", "workFlowId", "currentStatus", "roleGroup", "disable", "isOldValue", "isAdded", "isDelete", "isUpdateOld", "isUpdateNew"],
    name: "TableComponent",
    components: {
    },
    data() {
        return {
            noFooter: false,
            newRow: false,
            columnNames: [],
            data: [
                {}
            ],
            readOnly: true,
            rowLimit: 0,
            summaryColumns: [],
            summary: {},
            dataTableHeaders: [],
            changeDetected: false,
            updatedColumns:{}
        };
    },
    methods: {
        sendTableData() {
            this.summary = {};
            let res = this.data
            for (let item of res) {
                for (let eachKey in item) {
                    if (item[eachKey].includes('§')) {
                        item[eachKey] = '';
                    }
                }
                delete item['$id']
            }
            this.calculateSummary();
            this.$emit("input", res);
        },
        calculateSummary(){
            for(let eachData of this.data){
                for(let eachItem in eachData){
                    if(new Set(this.summaryColumns).has(eachItem)){
                        if(!this.summary[eachItem]) {
                            this.$set(this.summary, eachItem, Number(eachData[eachItem]));
                        } else {
                            this.$set(this.summary, eachItem, this.summary[eachItem] + Number(eachData[eachItem]));
                        }
                    }
                }
            }
        },
        checkAccess(accessList, type) {
            let bool = true;
            if(this.disable !== undefined && this.disable){
                return true;
            }
            if (accessList && accessList['ALL']) {
                if (accessList['ALL']['ALL']) {
                    if (accessList['ALL']['ALL'].includes('ALL')) {
                        bool = false;
                    }
                    else if (accessList['ALL']['ALL'].includes(this.roleGroup)) {
                        bool = false;
                    }
                }
                else if (accessList['ALL'][this.currentStatus]) {
                    if (accessList['ALL'][this.currentStatus].includes('ALL')) {
                        bool = false;
                    }
                    else if (accessList['ALL'][this.currentStatus].includes(this.roleGroup)) {
                        bool = false;
                    }
                }
                else if (this.mode == 'ADD' && accessList['ALL']['defaultStage']) {
                    if (accessList['ALL']['defaultStage'].includes('ALL')) {
                        bool = false;
                    }
                    else if (accessList['ALL']['defaultStage'].includes(this.roleGroup)) {
                        bool = false;
                    }
                }
            }
            else if (accessList && accessList[this.workFlowId]) {
                if (accessList[this.workFlowId]['ALL']) {
                    if (accessList[this.workFlowId]['ALL'].includes('ALL')) {
                        bool = false;
                    }
                    else if (accessList[this.workFlowId]['ALL'].includes(this.roleGroup)) {
                        bool = false;
                    }
                }
                else if (accessList[this.workFlowId][this.currentStatus]) {
                    if (accessList[this.workFlowId][this.currentStatus].includes('ALL')) {
                        bool = false;
                    }
                    else if (accessList[this.workFlowId][this.currentStatus].includes(this.roleGroup)) {
                        bool = false;
                    }
                }
                else if (this.mode == 'ADD' && accessList[this.workFlowId]['defaultStage']) {
                    if (accessList[this.workFlowId]['defaultStage'].includes('ALL')) {
                        bool = false;
                    }
                    else if (accessList[this.workFlowId]['defaultStage'].includes(this.roleGroup)) {
                        bool = false;
                    }
                }
            }
            if ((this.mode == 'UPDATE' || this.mode == 'ADD' || this.mode == 'STAGEUPDATE') && type == 'addNewRowAccess' && !bool) {
                return true;
            }
            else if ((this.mode == 'UPDATE' || this.mode == 'ADD' || this.mode == 'STAGEUPDATE') && type == 'addNewRowAccess' && bool) {
                return false;
            }
            return bool;
        },
        getWidth(columnName){
            let oneCharToPixelValue = 8;
            let lengthOfColumnName = columnName.length;
            let resultantPixelValue = lengthOfColumnName * oneCharToPixelValue;
            resultantPixelValue = (resultantPixelValue + 16 )+"px"; // add two more pixel value for perfect positioning
            return resultantPixelValue;
        },
        checkNewRow(){
            let addNewRowAccess = this.checkAccess(this.addNewRow, 'addNewRowAccess');
            if(this.rowLimit !== undefined && this.data.length < this.rowLimit && addNewRowAccess){
                this.newRow = true;
            } else if(addNewRowAccess && this.rowLimit === undefined){
                this.newRow = true;
            } else {
                this.newRow = false;
            }
        },
        getDataTableColumnWidth(columnName) {
            let oneCharToPixelValue = 8;
            let lengthOfColumnName = columnName.length;
            let resultantPixelValue = lengthOfColumnName * oneCharToPixelValue;
            resultantPixelValue = (resultantPixelValue + 16 )+"px"; // add two more pixel value for perfect positioning
            return resultantPixelValue;
        },
        getHighlightClass(item, columnName, index) {
            let stringifiedColumns = JSON.parse(JSON.stringify(this.updatedColumns));
            if(stringifiedColumns){
                for(let column in stringifiedColumns){
                    if(column == columnName && stringifiedColumns[column].newValue !== stringifiedColumns[column].oldValue){
                        if(this.isUpdateOld){
                            return 'highlight_delete'
                        } else if(this.isUpdateNew){
                            return 'highlight_add'
                        }
                    } else{
                        return;
                    }
                }
            }
            const oldItem = this.oldItems[index];
            if (!oldItem || oldItem[columnName] !== item[columnName]) {
              return 'highlight';
            } else {
              return '';
            }
          }
    },
    mounted() {
        if (this.mode == 'ADD' || this.mode == 'STAGEUPDATE') {
            this.data = this.data.slice(1);
            this.noFooter = true;
            this.rowLimit = this.tableConfiguration.rowLimit;
            for (let column of this.tableConfiguration.columns) {
                this.columnNames.push(column)
            }
            if (this.value) {
                this.data = this.value;
                for (let item of this.data) {
                    delete item['$id']
                }
            }
            else if (this.tableConfiguration && this.tableConfiguration.rows) {
                this.data = this.tableConfiguration.rows;
            }
            else {
                let columns = {}
                this.columnNames.forEach(element => {
                    columns[element.name] = ''
                });
                this.data.push(columns);
            }
        }
        else if (this.mode == 'VIEW') {
            this.noFooter = true;
            this.readOnly = true;
            for (let column of this.tableConfiguration.columns) {
                this.columnNames.push(column)
            }
            if(this.dataItems){
                let tableRows = [];
                if(this.isAdded || this.isDelete){
                    this.changeDetected = true
                }
                for(let item of this.dataItems){
                    if(Object.keys(item).includes('updateColumns')){
                        this.changeDetected = true
                        this.updatedColumns = item['updateColumns'];
                    }
                    if(Object.keys(item).includes('row')){
                        let idExists = this.dataItems.some(existingItem => existingItem.id === item.row.id);
                        if (!idExists) {
                            tableRows.push(item.row);
                        }
                        if (this.updatedColumns) {
                            let stringifiedColumns = JSON.parse(JSON.stringify(this.updatedColumns));
                            tableRows = tableRows.map(row => {
                                let updatedRow = { ...row };
                                
                                for (let key in stringifiedColumns) {
                                    if (updatedRow.hasOwnProperty(key)) {
                                    updatedRow[key] = this.isOldValue 
                                        ? stringifiedColumns[key].oldValue 
                                        : stringifiedColumns[key].newValue;
                                    }
                                }
                                return updatedRow;
                            });
                        }
                    }
                }
                this.data = tableRows.length > 0 ? tableRows : this.dataItems;
                for (let item of this.data) {
                    delete item['$id']
                }
            }
            this.dataTableHeaders = this.columnNames.map((column) => {
                return {
                    text: column.name,
                    value: column.name,
                    sortable: false,
                    align: 'start',
                    width: column.width ? column.width : this.getDataTableColumnWidth(column.name)
                }
            })
            if(this.oldItems){
                this.changeDetected = true;
            }
        }
        else if (this.mode == 'UPDATE') {
            this.data = this.data.slice(1);
            this.noFooter = true;
            this.rowLimit = this.tableConfiguration.rowLimit;
            for (let column of this.tableConfiguration.columns) {
                this.columnNames.push(column)
            }
            if (this.dataItems) {
                this.data = this.dataItems;
            } else if(this.dataItems === undefined && this.tableConfiguration && this.tableConfiguration.rows){
                this.data = this.tableConfiguration.rows;
            } else {
                let columns = {}
                this.columnNames.forEach(element => {
                    columns[element.name] = ''
                });
                this.data.push(columns);
            }
        }
        this.checkNewRow();
        if(this.mode !== 'VIEW'){
            this.summaryColumns = this.columnNames.filter((column) => column.ColumnSummary ).map((column) => column.name );
            if(this.mode == 'UPDATE'){
                this.calculateSummary();
            }
        }
        this.sendTableData();
    }
};
</script>  
<style>
.title-box {
    position: absolute;
    top: 0;
    background-color: white;
    width: 100%;
    max-height: 50px;
}
.tableComponentDataTable {
    border: 1px solid #d3d3d3;
}
.tableComponentDataTable .v-data-table-header th {
    background-color: #e9ecef !important;
}
.tableComponentDataTable table {
    border-collapse: separate;
}

.tableComponentDataTable .v-data-table-header th {
  border-bottom: 1px solid #d3d3d3;
}
.tableComponentDataTable .v-data-table-header th:not(:last-child) {
  border-right: 1px solid #d3d3d3;
}

.highlight {
  background-color: #FFF9C4;
  font-weight: 500;
}

.highlight_add {
    background-color: rgba(184, 226, 184, 1);
    font-weight: 500;
}

.highlight_delete {
    background-color: rgba(250, 198, 186, 1);
    font-weight: 500;
}
</style>