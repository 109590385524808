import axios from "axios";
import {getFromLocalStorage, setToLocalStorage} from "../store/localStorageInterface"
export const nrcValidateMixin = {
  inheritAttrs: false,
  methods: {
    async validateNRC(nrc){
      try{
        let nrcValidationResponse = await axios.post(
          process.env.VUE_APP_BACKEND_URL + "/validateNRC", 
          {
            applicationId: this.$route.query.applicationId,
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
            nrc: nrc
          }
        );
        if(nrcValidationResponse.data.isValid) {
          return true;
        }
        else{
          return false;
        }
      }
      catch(error){
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          this.$root.$emit("update_session_value", true);
        } else {
          this.errorMessageShow = true;
          if (
            error.response &&
            error.response.data &&
            error.response.data.errorObject
          ) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: error.response.data.errorObject.error_code,
              error_uuid: error.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
        }
      }
    }
  }
}